<script setup lang="ts">
import { useWindowSize } from '@vueuse/core';
import { Dialog, TransitionChild, TransitionRoot } from '@headlessui/vue';

// Props
const value = defineModel<boolean>();
const props = defineProps<{
  title?: string;
  width?: string;
}>();

// Static
const modal = ref(null);

// Composables
const { width: windowWidth, height } = useWindowSize();

// Computed
const isSmallerThanWidth = computed(() => {
  if (props.width) {
    return windowWidth.value <= parseInt(props.width) + 32;
  }
  return true;
});
</script>

<template>
  <TransitionRoot as="template" :show="value">
    <Dialog as="div" class="relative z-[9998]" @close="value = false">
      <TransitionChild
        as="template"
        enter="ease-out duration-200"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>
      <div class="fixed inset-0 z-[9999] w-screen overflow-y-auto">
        <div class="flex min-h-full justify-center p-2 items-center">
          <TransitionChild
            as="template"
            enter="ease-out duration-200"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              ref="modal"
              class="bg-white flex flex-col shadow rounded-lg"
              :class="isSmallerThanWidth ? 'w-full' : ''"
              :style="`width: ${width}px; max-height: ${height - 32}px`"
            >
              <div v-if="title || $slots.header" class="border-b border-gray-200 text-gray-900 p-4 flex items-center justify-between">
                <h3 class="text-base font-semibold leading-6 text-gray-900">
                  {{ title }}
                </h3>
                <div class="space-x-2 flex items-center">
                  <slot v-if="$slots.header" name="header" />
                  <div v-else class="h-[24px] w-[24px] flex items-center justify-center rounded-sm cursor-pointer" @click="value = false">
                    <ui-icon class="w-5.5 h-5.5" name="X" />
                  </div>
                </div>
              </div>
              <div class="p-2 sm:p-4 flex-auto overflow-auto" :class="!$slots.footer ? 'pb-8' : ''">
                <slot />
              </div>
              <div v-if="$slots.footer" class="border-t border-gray-200 gap-2 px-4 py-4 flex items-center justify-end flex-none">
                <slot name="footer" />
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
